export default function (hash) {
  hash = hash.replace(/^#/, '')
  try {
    return JSON.parse(decodeURIComponent(hash))
  } catch (e) {
    return parseQuery(hash)
  }
}

function parseQuery(str) {
  const result = {}
  str.split(/&/g).forEach(piece => {
    const match = /^([^=]*)=(.*)$/.exec(piece)
    if (match) {
      result[decodeURIComponent(match[1])] = decodeURIComponent(match[2])
    } else {
      result[piece] = ''
    }
  })
  return result
}
