<template>
  <v-app :style="{ background: isTopWindow ? undefined : 'transparent' }">
    <v-main>
      <watch-height>
        <router-view />
      </watch-height>
    </v-main>
  </v-app>
</template>

<script>
import WatchHeight from './util/WatchHeight'

export default {
  components: {
    WatchHeight,
  },
  data() {
    return {
      isTopWindow: window.top === window,
    }
  },
}
</script>
