<template lang="pug">
div(ref='container')
  slot
</template>

<script>
export default {
  mounted() {
    this.installObserver()
    this.installMessageHandler()
    this.setOverflow()
  },
  methods: {
    installObserver() {
      const observer = new ResizeObserver(() => {
        document.documentElement.scrollTop = 0
        this.sendMessage()
      })
      observer.observe(this.$refs.container)
    },
    installMessageHandler() {
      window.addEventListener('message', e => {
        if (e.data?.type === 'updateWidgetHeight') {
          console.debug('Pricemotion: Force update widget height')
          this.sendMessage()
        }
      })
    },
    sendMessage() {
      console.debug('Pricemotion: Update widget height')
      window.parent.postMessage(
        JSON.stringify({
          type: 'setWidgetHeight',
          value: this.$refs.container.offsetHeight,
        }),
        '*'
      )
    },
    setOverflow() {
      document.documentElement.style.overflow =
        this.$store.state.query.overflow || 'hidden'
    },
  },
}
</script>
