import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    query: null,
  },
  mutations: {
    setQuery(state, query) {
      state.query = query
    },
  },
  getters: {
    apiKey(state) {
      return state.query.api_key
    },
  },
})
