import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from './views/Home.vue'
import parseHash from './util/parseHash'
import store from './store'
import i18n from './i18n'

Vue.use(VueRouter)

const routes = [
  connect('/', Home),
  connect('/widget', () => import('./views/Widget.vue')),
  connect('/rulesWidget', () => import('./views/RulesWidget.vue')),
  connect('/products/add', () => import('./views/products/Add.vue')),
  connect('/products/import', () => import('./views/products/Import.vue')),
]

function connect(path, component) {
  return {
    path,
    component,
  }
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  const query = parseHash(to.hash)
  store.commit('setQuery', query)
  if (typeof query.locale === 'string') {
    i18n.locale = query.locale.replace(/_/g, '-')
  } else {
    i18n.locale = query.locale || 'nl'
  }
  next()
})

export default router
